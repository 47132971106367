
.disable .category {
    opacity: 0.6;
    border: 2px solid #a6a6a6; /* Add 'solid' here */
    color: #a6a6a6;
    font-weight: 500;
}


.category {
    border-radius: 5px;
    border: 2px solid #54319f; /* Add 'solid' here */
    color: #54319f;
    font-size: 1.4rem;
    margin: 10px;
    padding: 10px;
    font-weight: 500;
    cursor: pointer;
}
