.inputWithButton {
    position: relative;
  }
  
  .inputWithButton input{
      font-family: "Inter", sans-serif;
      border-radius: 10px;
      padding: 1rem 1.25rem;
      border: 1px solid #E7E5E4;
      transition: ease-in-out 250ms;
    
  }
  
  input:hover,
  input:focus,
  .input:hover,
  .input:focus
   {
      outline: none;
      border: 1px solid #A78BFA;
      color: #3F3F46;
  }

  .inputWithButton span {
    position: absolute;
    right: 11px;
    top : 12px;
  }