.error-message {
  color: #a30000;
  padding: 19px;
  font-size: 19pt;
  margin: 14px;
  border-radius: 7px;
  background-color: #ffdfe3;

}

.text-red{
  color: #a20808;
  padding: 4px;
  font-size: 14pt;
}

.text-green{
  color: #00916d;
  padding: 4px;
  font-size: 14pt;
}


.active-filter {
  background-color: #8FFAD3;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  font-size: large;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 390px;
  height: 200px;
}
.modal-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Add this line to center items vertically */
}

.ml-16 {
  margin-left: 1rem;
}
.m-16 {
  margin: 1rem;
}
.pl-16 {
  padding-left: 1rem;
}
