/* Password input container */
.password-input-container {
    position: relative; /* Keep the container relative for proper positioning */
    display: flex;
}

.password-input-container input {
    width: 100%; /* Set input width to 100% for available space */
}

/* Eye icon */
.password-input-container .eye {
    position: absolute; /* Maintain relative positioning */
    top: 15%; /* Position the eye icon vertically in the center */
    right: 7px; /* Set right position with a slight offset from the input's right border */
    transform: translateY(-50%); /* Shift icon vertically for input alignment */
    transform: translateX(-50%); /* Shift icon horizontally for input alignment */
    cursor: pointer;
}