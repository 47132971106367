.stats-container {
    display: flex;
    flex-wrap: wrap;

}

.stat-item {
    flex: 1 2 30.30%; /* 1 = grow factor, 0 = shrink factor, 33.33% = basis width */
    background-color: #eeecff;
    border-radius: 10px;
    padding: 15px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
}

.notes-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: 20px;
}

/*.note-box {*/
/*    flex: 1 1 calc(33.33% - 20px); !* Adjust the percentage as needed *!*/
/*    padding: 10px;*/
/*    border: 1px solid #ccc;*/
/*    margin: 5px;*/
/*    box-sizing: border-box;*/
/*}*/



/*.container {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*}*/

/*.item {*/
/*    flex: 1 0 33.33%; !* 1 = grow factor, 0 = shrink factor, 33.33% = basis width *!*/
/*    margin: 10px;*/
/*    min-height: 200px; !* Ensure items have a consistent minimum height *!*/
/*}*/
