.togglebutton {
  padding: 0px 0px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); /* Add box shadow */
  width: 55px;
}

.JA {
  background-color: #5cb85c;
  color: white;
  border-radius: 8px;

}

.NEJ {
  background-color: #d9534f;
  color: white;
  border-radius: 8px;

}
