/* Spinner.css */
.full-page-spinner {
    position: fixed;
    bottom: 0;
    width: 100vw;
    font-size: 65pt;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.78); /* Optional: semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 40; /* Ensure a higher z-index than the footer */
}

  
  .spin {
    /* Add your spin animation here */
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  