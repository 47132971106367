.container {
    /* display: grid; */
    grid-template-columns: repeat(2, 1fr); /* Adjust the number of columns here */
    /* gap: 10px;  */
  }
  
  .row {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 5px;
  }
  

.pr-16 {
  padding-right: 1rem;

}

.pr-24 {
  padding-right: 1.4rem;

}

.pr-32 {
  padding-right: 2rem;

}

.pl-32 {
  padding-left: 2rem;
}

.pt-32 {
  padding-top: 2rem;
}
.pt-64 {
  padding-top: 4rem;
}

.pt-16 {
  padding-top: 1rem;
}

.pl-16 {
  padding-left: 1rem;
}

.pb-16 {
  padding-bottom: 1rem;
}

.pb-32 {
  padding-bottom: 2rem;
}

.p-64 {
  padding: 4rem;
}

.p-32 {
  padding: 2rem;
}

.p-16 {
  padding: 1rem;
}


.mb-16 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 0.5rem;
}

.pb-8 {
  padding-bottom: 0.5rem;
}
.p-8 {
  padding: 0.5rem;
}

.pt-8 {
  padding-top: 0.5rem;
}

.mb-32 {
  margin-bottom: 2rem;
}

.mb-64 {
  margin-bottom: 4rem;
}

.mt-64 {
  margin-top: 4rem;
}

.mt-128 {
  margin-top: 8rem;
}

.mt-32 {
  margin-top: 2rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 0.5rem;
}

.mb-16 {
  margin-bottom: 1rem;

}

.mb-32 {
  margin-bottom: 2rem;

}

.mb-80 {
  margin-bottom: 5rem;
}

.pb-48 {
  padding-bottom: 3rem;
}

.pb-64 {
  padding-bottom: 4rem;
}

.pb-80 {
  padding-bottom: 5rem;
}

.ml-32 {
  margin-left: 2rem;
}
.ml-16 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 0.5rem;
}
.mr-8 {
  margin-right: 0.5rem;
}


.p-8 {
  padding: 0.5rem;
}