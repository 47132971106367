/* Password input container */
.password-input-container {
    position: relative; /* Keep the container relative for proper positioning */
    display: flex;
}

.password-input-container input {
    width: 100%; /* Set input width to 100% for available space */
}

/* Eye icon */
.password-input-container .eye {
    position: absolute; /* Maintain relative positioning */
    right: 0px; /* Set right position with a slight offset from the input's right border */
    transform: translateY(-50%); /* Shift icon vertically for input alignment */
    transform: translateX(-50%); /* Shift icon horizontally for input alignment */
    cursor: pointer;
}

.mb-8 {
    margin-bottom: 0.5rem;
}

.pb-8 {
    padding-bottom: 0.5rem;
}
.mt-8 {
    margin-top: 0.5rem;
}

.pt-8 {
    padding-top: 0.5rem;
}